import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import store from '../../../../../store';
export default {
  name: 'unClearReceipt',
  components: {
    ModelSelect,
    BasicSelect,
    DatePicker
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.receiptSearch();
    },
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    },
    selectedLeagalEntity: function() {
      this.getProjectList(this.selectedLeagalEntity.value);
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    defaultClearingGlDate: function() {}
  },
  data() {
    return {
      unsubscribe: null,
      defaultClearingGlDate: null,
      defaultClearingDate: null,
      payload: null,
      defaultReversalComments: null,
      dateRangePicker: {
        opens: true,
        autoApply: true,
        dateRange: null,
        dateFormat: appStrings.DATEFNSFORMAT
      },
      showCreateReceipt: false,
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ]
      },
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      customerName: '',
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedInstrumentStatus: {
        value: null,
        text: null
      },
      selectedAccountNum: {
        value: null,
        text: null
      },
      instrumentStartAmount: null,
      instrumentEndAmount: null,
      isAdvanceSearchEnable: false,
      search: '',
      clearReceiptList: [],

      update: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,

      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      creation_start_date: null,
      creation_end_date: null,
      gl_start_date: null,
      gl_end_date: null,
      value_start_date: null,
      value_end_date: null,
      valueDate: null,
      glDate: null,
      creationDate: null,
      selectAllCheckBox: false,
      clearingStatusList: [],
      selectedClearingStatus: {
        value: null,
        text: null
      },
      clearingReasonList: [],
      selectedClearingReason: {
        value: null,
        text: null
      },
      selectedCheckBoxIndexArray: [],
      fields: [
        {
          key: 'selectbox',
          label: 'Select All',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'clearing_date'
        },
        {
          key: 'clearing_gl_date'
        },
        {
          key: 'clearing_status'
        },
        {
          key: 'reversal_reason',
          label: 'Reason'
        },
        {
          key: 'reversal_comments'
        },
        {
          key: 'receipt_number'
        },
        {
          key: 'receipt_date'
        },
        {
          key: 'value_date'
        },
        {
          key: 'instrument_number'
        },
        {
          key: 'instrument_date'
        },
        {
          key: 'instrument_amount'
        },
        {
          key: 'sub_unit_name'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'gl_date',
          label: 'Process GL Date'
        },
        {
          key: 'receipt_method_name',
          label: 'Receipt Method'
        },

        {
          key: 'period_name'
        },
        {
          key: 'bank_account_name'
        },
        {
          key: 'account_num'
        },
        {
          key: 'narration'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.applyClearReceipt();
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fms/fmsFilter',
            'unclear-Receipt',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getReceiptAgainast();
    this.getLegalEntity();
    this.getPaymentMode();
    this.getDrawnOn();
    this.getInstrumentStatus();
    this.getClearingStatusList();
    this.getClearingReasonsList();
    // this.getProfileOption('DATE_FORMAT');
    // this.getProfileOption('FMS_GL_DATE_SEARCH_RANGE');
  },
  methods: {
    selectedTowerFun(towerVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
      this.lovId = towerVal.value;
      this.selectedTower = towerVal;
      this.getOtherLovByProjectId();
    },
    selectedFloorFun(floorVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.selectedFloor = floorVal;
      this.lovId = floorVal.value;
      this.getOtherLovByProjectId();
    },
    selectedUnitFun(unitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSUNIT;
      this.selectedFloor = unitVal;
      this.lovId = unitVal.value;
      this.getOtherLovByProjectId();
    },
    selectedSubUnitFun(subUnitVal) {
      this.lovFieldName = appStrings.FMSLOV.FMSSUBUNIT;
      this.selectedSubUnit = subUnitVal;
      this.lovId = subUnitVal.value;
      this.getOtherLovByProjectId();
    },
    getLegalEntity() {
      const payload = store.state.shared.responsibilityId;
      this.$store
        .dispatch('receipt/getOrganizationLov', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.advanceSearchForm.leagalEntity = leagalEntity;
            this.selectedLeagalEntity =
              leagalEntity && leagalEntity.length
                ? leagalEntity[0]
                : this.selectedLeagalEntity;
            this.getProjectList(this.selectedLeagalEntity.value);
          }
        });
    },
    getReceiptAgainast() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_SEARCH_BY')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.receiptAgainst = getValueAndText;
            this.selectedReceiptAgainst =
              getValueAndText && getValueAndText.length
                ? getValueAndText[0]
                : this.selectedReceiptAgainst;
          }
        });
    },
    getPaymentMode() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'PAYMENT_MODE')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.paymentMode = getValueAndText;
          }
        });
    },
    getClearingStatusList() {
      this.$store
        .dispatch('shared/getLOVBySetType', 'CLEARING_STATUS')
        .then(response => {
          if (response.status === 200) {
            const getValueAndText = response.data.data.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.clearingStatusList = getValueAndText;
            const defaultClear = getValueAndText.find(
              clearStatus => clearStatus.value === 'CLEAR'
            );
            this.selectedClearingStatus = defaultClear;
          }
        });
    },
    getClearingReasonsList() {
      this.$store
        .dispatch('shared/getLOVBySetType', 'REASON_VSET')
        .then(response => {
          if (response.status === 200) {
            const getValueAndText = response.data.data.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.clearingReasonList = getValueAndText;
          }
        });
    },
    //     getProfileOption(profileType) {
    //       this.$store
    //         .dispatch('receipt/getOptionProfileByProfileType', profileType)
    //         .then(response => {
    // //          console.log('response', response);
    //         });
    //     },
    getInstrumentStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'RECEIPT_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.instrumentStatusList = getValueAndText;
            this.selectedInstrumentStatus = {
              value: 'REMITTED',
              text: 'Remitted'
            };
          }
        });
    },
    getDrawnOn() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BANK_NAME_VSET')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_set_dtl_id,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.drawnOn = getValueAndText;
          }
        });
    },
    getProjectList(leId) {
      const payload = {
        typeOfProject: 'FMSPRJ',
        le_id: leId
      };
      this.$store.dispatch('receipt/getProjectList', payload).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.proj_id,
              text: type.proj_name
            };
          });
          this.advanceSearchForm.projectList = getValueAndText;
        }
      });
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
              this.getSectorList(results.sectors);
              this.getPocketList(results.pockets);
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
              this.getTowerList(results.towers);
              this.getUnitList(results.units);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getSubUnitList(results.subunits);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSSUBUNIT) {
              this.getTowerList(results.towers);
              this.getFloorList(results.floors);
              this.getUnitList(results.units);
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getSectorList(sectorList) {
      if (sectorList && sectorList.length) {
        const valueAndTextArr = sectorList.map(type => {
          return {
            value: type.sector_id,
            text: type.sector_name
          };
        });
        this.advanceSearchForm.sectorList = valueAndTextArr;
        this.selectedSector =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSector = this.keyValue;
        this.advanceSearchForm.sectorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getPocketList(pocketList) {
      if (pocketList && pocketList.length) {
        const valueAndTextArr = pocketList.map(type => {
          return {
            value: type.pocket_id,
            text: type.pocket_name
          };
        });
        this.advanceSearchForm.pocketList = valueAndTextArr;
        this.selectedPocket =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedPocket = this.keyValue;
        this.advanceSearchForm.pocketList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getTowerList(towerList) {
      if (towerList && towerList.length) {
        const valueAndTextArr = towerList.map(type => {
          return {
            value: type.tower_id,
            text: type.tower_name
          };
        });
        this.advanceSearchForm.towerList = valueAndTextArr;
        this.selectedTower =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedTower = this.keyValue;
        this.advanceSearchForm.towerList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getFloorList(floorList) {
      if (floorList && floorList.length) {
        const valueAndTextArr = floorList.map(type => {
          return {
            value: type.floor_id,
            text: type.floor_name
          };
        });
        this.advanceSearchForm.floorList = valueAndTextArr;
        this.selectedFloor =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedFloor = this.keyValue;
        this.advanceSearchForm.floorList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getUnitList(unitList) {
      if (unitList && unitList.length) {
        const valueAndTextArr = unitList.map(type => {
          return {
            value: type.unit_id,
            text: type.unit_name
          };
        });
        this.advanceSearchForm.unitList = valueAndTextArr;
        this.selectedUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedUnit = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getSubUnitList(subUnitList) {
      if (subUnitList && subUnitList.length) {
        const valueAndTextArr = subUnitList.map(type => {
          return {
            value: type.sub_unit_id,
            text: type.sun_unit_name
          };
        });
        this.advanceSearchForm.subUnitList = valueAndTextArr;
        this.selectedSubUnit =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.keyValue;
      } else {
        this.selectedSector = this.keyValue;
        this.advanceSearchForm.unitList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    enableAdvanceSearch() {
      this.isAdvanceSearchEnable = !this.isAdvanceSearchEnable;
    },
    rowSelected(item) {
      this.showCreateReceipt = true;
      this.eventBus.$emit('getReceiptId', item.fms_receipt_hdr_id);
    },
    valueChange: function() {
      this.addressForm.state.value = '';
      this.addressForm.state.text = '';
      this.addressForm.city.value = '';
      this.addressForm.city.text = '';
      this.addressForm.pincode.value = '';
      this.addressForm.pincode.text = '';
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetSearchForm() {
      this.selectedPaymentMode = this.keyValue;
      this.selectedDrawnOn = this.keyValue;
      this.selectedProject = this.keyValue;
      this.selectedSector = this.keyValue;
      this.selectedPocket = this.keyValue;
      this.selectedTower = this.keyValue;
      this.selectedFloor = this.keyValue;
      this.selectedUnit = this.keyValue;
      this.selectedSubUnit = this.keyValue;
      this.selectedInstrumentStatus = this.keyValue;
      this.gl_start_date = null;
      this.gl_end_date = null;
      this.creation_start_date = null;
      this.creation_end_date = null;
      this.value_start_date = null;
      this.value_end_date = null;
      this.valueDate = null;
      this.glDate = null;
      this.creationDate = null;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.addressNameError = '';
      this.update = false;
      this.showAlert = false;
      this.resetSearchForm();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSelect(event) {
      this.selectedReceiptAgainst = event;
    },
    receiptSearch() {
      this.loader = true;
      this.payload = {
        searchParams: {
          with_unit: this.selectedReceiptAgainst.value === 'SUBUNIT',
          le_id: this.selectedLeagalEntity.value,
          receipt_mode_vset: this.paymentMode,
          document_num: this.receiptNum,
          customer_name: this.customerName,
          bank_vset: this.selectedDrawnOn.value,
          prj_id: this.selectedProject.value,
          sector_id: this.selectedSector.value,
          pocket_id: this.selectedPocket.value,
          tower_id: this.selectedTower.value,
          floor_id: this.selectedFloor.value,
          unit_id: this.selectedUnit.value,
          sub_unit_id: this.selectedSubUnit.value,
          value_start_date: this.valueDate
          ? format(this.valueDate[0], appStrings.DATEFNSFORMAT)
          : null,
          value_end_date: this.valueDate
          ? format(this.valueDate[1], appStrings.DATEFNSFORMAT)
          : null,
          gl_start_date: this.glDate
          ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
          : null,
          gl_end_date: this.glDate
          ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
          : null,
          instrument_start_date: null,
          instrument_end_date: null,
          instrument_status_vset: this.selectedInstrumentStatus.value,
          party_receipt_method_id: null,
          instrument_start_amount: this.instrumentStartAmount,
          instrument_end_amount: this.instrumentEndAmount,
          creation_start_date: this.creationDate
          ? format(this.creationDate[0], appStrings.DATEFNSFORMAT)
          : null,
          creation_end_date: this.creationDate
          ? format(this.creationDate[1], appStrings.DATEFNSFORMAT)
          : null
        },
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.$store
        .dispatch('fms/fmsFilter', this.payload)
        .then(response => {
          this.isAdvanceSearchEnable = false;
          this.loader = false;
          if (response.status === 200) {
            this.clearReceiptList = response.data.data.page.map(receipt => {
              receipt.selectbox = false;
              receipt.selectedClearingReason = {
                value: receipt.reversal_reason_vset,
                text: receipt.reversal_reason
              };
              receipt.selectedClearingStatus = {
                value: null,
                text: null
              };
              receipt.clearing_status = 'CLEAR';
              return receipt;
            });
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    checkUncheckAll() {
      this.clearReceiptList = this.clearReceiptList.map(receipt => {
        receipt.selectbox = this.selectAllCheckBox;
        return receipt;
      });
    },
    selectAllBoxChecked(flag) {
      if (!flag) {
        this.selectedCheckBoxIndexArray = [];
      }
    },
    selectBoxChecked(flag, index) {
      if (flag) {
        this.selectedCheckBoxIndexArray.push(index);
      } else {
        this.selectedCheckBoxIndexArray.splice(index, 1);
      }
    },
    clearDefaultParams() {
      this.defaultReversalComments = null;
      this.selectedClearingReason = {
        value: null,
        text: null
      };
      this.selectedClearingStatus = {
        value: null,
        text: null
      };
      this.defaultClearingDate = null;
      this.defaultClearingGlDate = null;
    },
    applyDefault() {
      if (this.selectedCheckBoxIndexArray.length === 0) {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = 'Please select at least one receipt to clear';
      } else {
        this.selectedCheckBoxIndexArray.forEach(receiptIndex => {
          if (this.clearReceiptList[receiptIndex].clearing_date === null) {
            this.clearReceiptList[
              receiptIndex
            ].clearing_date = this.defaultClearingDate;
          }
          if (this.clearReceiptList[receiptIndex].clearing_gl_date === null) {
            this.clearReceiptList[
              receiptIndex
            ].clearing_gl_date = this.defaultClearingGlDate;
          }
          //   this.clearReceiptList[
          //     receiptIndex
          //   ].clearing_gl_date = this.defaultClearingGlDate;
          //   this.clearReceiptList[
          //     receiptIndex
          //   ].clearing_gl_date = this.defaultClearingGlDate;
          if (this.clearReceiptList[receiptIndex].reversal_comments === null) {
            this.clearReceiptList[
              receiptIndex
            ].reversal_comments = this.defaultReversalComments;
          }
        });
      }
    },
    applyClearReceipt() {
      if (this.selectedCheckBoxIndexArray.length === 0) {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = 'Please select at least one receipt to clear';
      } else {
        const filterSelectedBoxData = this.clearReceiptList.filter(
          receipt => receipt.selectbox
        );
        const receiptPayloadData = filterSelectedBoxData.map(receipt => {
          return {
            fms_receipt_hdr_id: receipt.fms_receipt_hdr_id,
            clear_date:
              this.defaultClearingDate === null
                ? format(
                    new Date(receipt.clearing_date),
                    appStrings.DATEFNSFORMAT
                  )
                : format(
                    new Date(this.defaultClearingDate),
                    appStrings.DATEFNSFORMAT
                  ),
            gl_date:
              this.defaultClearingGlDate === null
                ? format(
                    new Date(receipt.clearing_gl_date),
                    appStrings.DATEFNSFORMAT
                  )
                : format(
                    new Date(this.defaultClearingGlDate),
                    appStrings.DATEFNSFORMAT
                  )
          };
        });
        this.loader = true;
        this.payload = {
          unclear_receipts: receiptPayloadData
        };
        this.$store
          .dispatch('fms/getReceiptUnClear', this.payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.showReceiptPreview = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.receiptSearch();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.data.message;
            }
          })
          .catch(() => {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
            this.loader = false;
          });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
